import { summonPopUp, removePopUp } from "../popUp/popUp";

const setCookie = (name, value) => {
  const updatedCookie = `${encodeURIComponent(name)}=${encodeURIComponent(value)};path=/;max-age=31536000;`;
  document.cookie = updatedCookie;
};

const getCookie = (name) => {
  const matches = document.cookie.match(new RegExp(`(?:^|; )${name.replace(/([.$?*|{}()[\]\\/+^])/g, '\\$1')}=([^;]*)`));
  return matches ? decodeURIComponent(matches[1]) : undefined;
};

const showMessage = () => {
  summonPopUp('#cookie', false);
  const agreeButton = document.querySelector('.cookie__agree');

  agreeButton.addEventListener('click', () => {
    setCookie('agreeCookie', true);
  });
};

const cookie = () => {
  console.log('cookie');
  const result = getCookie('agreeCookie');
  if (result === undefined) {
    showMessage();
  }
};

cookie();
